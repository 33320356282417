<template>
  <div class="cashbackSearch" ref="cashbackSearch">
    <div class="top">
      <div class="back-box">
        <!--        <img class="back-home" @click="handleReturn" src="https://cdn.cailu88.com/jingxuanshi/arrow_back%402x.png"/>-->
        <!--        <div class="text">京东补贴红包</div>-->
      </div>
      <div class="search-box">
        <img class="search-logo" src="https://cdn.cailu88.com/jingxuanshi/cashback_search.png"/>
        <form action="" v-on:submit.prevent style="width: 67%;">
          <input class="search-content" ref="searchContent" placeholder="搜索京东商品，领取补贴红包" type="search" v-model="keyword"
                 @focus="inputFocus" @keyup.enter="handleSearch">
        </form>
        <img v-if="keyword != ''" class="close" src="https://cdn.cailu88.com/jingxuanshi/input_close@2x.png"
             @click="handleDeleteInput"/>
        <span class="search-btn" @click="handleSearch">搜索</span>
      </div>
    </div>
    <div v-if="!hasResult" class="how-to-search">
      <div class="title">如何查找专属补贴</div>
      <img src="https://cdn.cailu88.com/jingxuanshi/learn_search.png" class="learn-pic"/>
    </div>
    <div v-else class="search-result">
      <div class="sort">
        <template v-for="(item, index) in sort_list">
          <div :key="index" :class="item.selected? 'sort-active' : ''" style="display: flex;align-items: center"
               @click="handleSort(item, index)">
            <p>{{ item.text }}</p>
            <div v-if="index === 3" class="sort-icon-wrap">
              <img class="sort-icon sort-up-icon"
                   :src="item.currentSort === 'asc'?'https://cdn.cailu88.com/jingxuanshi/sortUpActive.png':'https://cdn.cailu88.com/jingxuanshi/sortUpGray.png'"/>
              <img class="sort-icon sort-down-icon"
                   :src="item.currentSort === 'desc'?'https://cdn.cailu88.com/jingxuanshi/sortUpActive.png':'https://cdn.cailu88.com/jingxuanshi/sortUpGray.png'"/>
            </div>
          </div>
        </template>
        <div class="hasCoupon">
          <div class="text">仅搜有券</div>
          <div class="switch">
            <van-switch v-model="hasCoupon" active-color="#F2531C" inactive-color="#ffffff" size="18"
                        @click="handleHasCoupon"/>
          </div>
        </div>
      </div>
      <div class="goods-list" :style="{'background-color': goodsList.length?'#F9F9F9': '#fff'}">
        <template v-for="(item, index) in goodsList">
          <div class="goods-list-wrap">
            <div class="goods-image">
              <img v-lazy="item.pictUrl">
            </div>
            <div class="goods-info">
              <div class="goods-title">
                {{ item.itemTitle }}
              </div>
              <div class="goods-discounts">
                <div class="vouchers-one" v-if="item.couponValue">
                  <span class="vouchers-text">券</span>
                  <span class="vouchers-number">{{ item.couponValue }}元 </span>
                </div>
                <div v-if="item.rebateValue>0" class="vouchers-two">
                  <span class="price-text">补贴</span>
                  <span class="price-number">{{ item.rebateValue }}元</span>
                </div>
              </div>
              <div class="goods-price">
                <div>
                  <p class="subsidies-price">补贴价 ¥ <span class="price">{{ item.zkFinalPrice }}</span></p>
                  <p class="grayfont">市场价 <span class="line-text">¥ {{ item.reservePrice }}</span></p>
                </div>
                <div class="button">
                  <span @click="go(item)">马上抢<van-icon name="arrow"/></span>
                </div>
              </div>
            </div>
          </div>
        </template>
        <div :class="['loading-wrap', goodsList.length < 5 ? 'loading-wrap-white':'']">
          <img v-if="goodsList.length < totalCount && totalCount !== 0" class="loading"
               src="https://cdn.cailu88.com/jingxuanshi/loading@2x.png"/>
          <p v-if="goodsList.length === totalCount && totalCount !== 0">
            暂无更多
          </p>
        </div>
      </div>
    </div>
    <Loading :click="click"></Loading>
  </div>
</template>
<script>
import Vue from 'vue'
import { Lazyload, Switch, Icon, Toast } from 'vant'
import Loading from '../../components/loading'
import baseUrl from '../../utils/baseUrl'

const api = require('../../utils/api').api

Vue.use(Switch)
Vue.use(Icon)
Vue.use(Toast)
Vue.use(Lazyload, {
  lazyComponent: true,
})
export default {
  name: 'cashbackSearch',
  data () {
    return {
      keyword: '',
      token: '',
      version: '',
      channel: '',
      click: true,
      hasResult: false,
      page: 1,
      pageSize: 20,
      totalCount: 0,
      sellerId: 0,
      itemSource: 2,
      hasCoupon: false,
      sortName: '',
      sort: '',
      goodsList: [],
      sort_list: [{
        text: '综合',
        sortName: '',
        selected: true,
      }, {
        text: '高返利',
        sortName: 'commissionShare',
        selected: false,
      }, {
        text: '销量',
        sortName: 'inOrderCount30Days',
        selected: false,
      }, {
        text: '价格',
        sortName: 'price',
        sort: ['asc', 'desc'],
        currentSort: '',
        selected: false,
      }],
    }
  },
  created () {
    this.token = this.$route.query.token
    this.version = this.$route.query.version
    this.channel = this.$route.query.channel
  },
  mounted () {
    this.$refs.searchContent.focus()
    window.addEventListener('scroll', this.lazyLoading) // 滚动到底部，再加载的处理事件
    this.$once('hook:beforeDestroy', () => {              // 通过$on,$once使用hook监听vue生命周期函数，进行监听销毁
      window.removeEventListener('scroll', this.lazyLoading)
    })

  },
  components: {
    Loading
  },
  methods: {
    // 马上抢
    go (item) {
      let that = this
      if (!this.click) {
        return false
      }
      this.click = false
      this.$axios('/item/bonus/send/v2', {
        params: {
          cilentType: 2,
          itemId: item.itemId,
          itemSource: item.itemSource,
          isPG: item.isPG,
          couponClickUrl: item.couponClickUrl,
          commission: item.maxCommissionValue || '',
          loginType: 2
        }
      }).then(res => {
        let event = 'jumpJD'
        let param = {
          url: res.data.data.spreadUrl,
        }
        this.$h5AppLink(event, param)
        that.click = true
      }).catch(err => {
        Toast.fail(err.data.message)
        that.click = true
      })
    },
    // 顶部返回按钮
    handleReturn () {
      this.$router.go(-1)
    },
    // 搜索栏focus事件
    inputFocus () {
    },
    lazyLoading () {
      // 获取 可视区高度`、`滚动高度`、`页面高度`
      let scrollTop = document.documentElement.scrollTop || document.body.scrollTop
      let clientHeight = document.documentElement.clientHeight
      let scrollHeight = document.documentElement.scrollHeight
      if (scrollTop + clientHeight >= scrollHeight) { // 滚动到底部，逻辑代码
        //事件处理
        this.page++
        if (this.goodsList.length < this.totalCount && this.totalCount !== 0) {
          this.handleSearch('add')
        }
      }
    },
    // 搜索栏搜索Goods
    handleSearch (type) {
      if (!this.click) {
        return false
      }
      this.click = false
      this.$axios(api.goodsSearch, {
        params: {
          bonus: true,
          keyword: this.keyword,
          sellerId: this.sellerId,
          hasCoupon: this.hasCoupon == true ? 1 : 0,
          sortName: this.sortName,
          sort: this.sort,
          itemSource: this.itemSource,
          page: this.page,
          pageSize: this.pageSize,
        },
      }).then(res => {
        this.totalCount = res.data.data.totalCount
        this.hasResult = true
        this.goodsList = type == 'add' ? [...this.goodsList, ...res.data.data.data] : res.data.data.data
        this.click = true
        this.$refs.searchContent.blur()
      }).catch(err => {
        this.click = true
        if (err.data.code == 'USER_UN_LOGIN') {
          let event = 'login'
          let param = {
            backUrl: `${baseUrl.pageUrl}/cashback/search`
          }
          this.$h5AppLink(event, param)
        }
      })
    },
    // 清除搜索
    handleDeleteInput () {
      this.keyword = ''
      this.handleSearch()
    },
    // 搜索券商品
    handleHasCoupon () {
      this.hasCoupon = !this.hasCoupon
      this.handleSearch()
    },
    // 排序搜索
    handleSort (info, listIndex) {
      if (this.sortName == info.sortName && info.sortName != 'price') {
        return
      }
      this.page = 1
      this.sort_list.map((item, index) => {
        if (listIndex === index) {
          item.selected = true
          if (index === 0) {
            this.sort = ''
          } else if (index >= 1 && index < 3) {
            this.sort = 'desc'
          } else if (index === 3) {
            if (item.currentSort === 'asc') {
              item.currentSort = 'desc'
            } else if (item.currentSort === 'desc' || item.currentSort === '') {
              item.currentSort = 'asc'
            }
            this.sort = item.currentSort
          }
        } else {
          item.selected = false
          if (index === 3) {
            item.currentSort = ''
          }
        }
      })
      this.sortName = info.sortName
      this.handleSearch()
    },
  }
}
</script>
<style lang="less" scoped>
.cashbackSearch {
  min-height: 100vh;

  .top {
    height: 65px;
    line-height: 25px;
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    z-index: 3;
    background: #F2531C;

    .back-box {
      height: 10px;
      color: white;
      display: flex;
      align-items: center;
      padding-left: 12px;
      box-sizing: border-box;
      font-size: 18px;

      .back-home {
        width: 20px;
        height: 20px;
        margin-right: 5px;
      }

      .text {
        margin-left: 104px;
      }
    }

    .search-box {
      position: relative;
      width: 357px;
      height: 35px;
      background: #FFFFFF;
      border-radius: 17px;
      margin: 8px auto 0 auto;
      display: flex;

      .search-logo {
        width: 16px;
        height: 16px;
        margin: 10px 14px 10px 14px;
      }

      .search-content {
        flex: 1;
        width: 100%;
        height: 100%;
        font-size: 15px;
        border: none;
      }

      // 去除输入框清除的按钮
      input[type="search"]::-webkit-search-cancel-button {
        display: none;
      }

      .search-btn {
        display: flex;
        height: 100%;
        font-size: 15px;
        color: #F2531C;
        align-items: center;
        margin: 0 17px;
      }

      .close {
        width: 18px;
        height: 18px;
        position: absolute;
        right: 62px;
        top: 9px;
        z-index: 2;
      }
    }
  }

  .how-to-search {
    margin-top: 12px;

    .title {
      font-size: 18px;
      font-weight: bold;
      color: #333333;
      line-height: 21px;
      margin-left: 16px;
    }

    .learn-pic {
      width: 100%;
      height: 47px;
      margin-top: 17px;
    }
  }

  .search-result {
    position: relative;

    .hasCoupon {
      width: 96px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: #999;

      .text {

      }

      .switch {
        height: 20px;
      }
    }

    .sort {
      position: sticky;
      top: 65px;
      display: flex;
      z-index: 999;
      justify-content: space-between;
      align-items: center;
      font-size: 14px;
      color: #333;
      background: #FFFFFF;
      line-height: 21px;
      padding: 12px 15px;

      .sort-active {
        color: #e44a59;
      }

      .sort-icon-wrap {
        display: inline-block;
        margin-left: 4px;
        height: 10px;

        .sort-icon {
          width: 8px;
          height: 4px;
          display: block;
        }

        .sort-down-icon {
          margin-top: 2px;
          transform: rotate(-180deg);
        }
      }

    }

    .goods-list {
      padding-top: 5px;

      .goods-list-wrap {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        margin: 0px 8px 8px 8px;
        padding: 10px;
        max-height: 140px;
        background: #fff;
        border-radius: 8px;

        .goods-image {
          & > img {
            width: 120px;
            height: 120px;
            border-radius: 8px;
          }
        }

        .goods-info {
          margin-left: 10px;
          width: 100%;
          position: relative;

          .goods-title {
            font-size: 14px;
            font-family: PingFang-SC-Regular, PingFang-SC;
            color: #333333;
            line-height: 20px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            line-clamp: 2;
            -webkit-box-orient: vertical;

          }

          .goods-discounts {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            height: 20px;
            font-size: 12px;
            font-family: PingFang-SC-Regular, PingFang-SC;
            /* color: #8b7f08; */
            line-height: 20px;
            margin-top: 3px;

            .vouchers-one {
              display: flex;
              justify-content: flex-start;
              align-items: center;
              height: 16px;
              line-height: 16px;
              color: #E44A59;
              margin-right: 7px;

              .vouchers-text {
                width: 16px;
                height: 16px;
                font-size: 10px;
                font-family: PingFang-SC-Regular, PingFang-SC;
                text-align: center;
                color: #fff;
                border-radius: 2px 0 0 2px;
                background-color: #E44A59;
                position: relative;
                display: flex;
                justify-content: center;
                align-items: center;
                z-index: 2;
              }

              .vouchers-text::after {
                content: '';
                width: 5px;
                height: 5px;
                border-radius: 50%;
                background-color: #fff;
                position: absolute;
                top: 50%;
                right: -3px;
                transform: translate(0, -50%);
                z-index: 2;
              }

              .vouchers-number {
                height: 16px;
                font-size: 11px;
                font-family: PingFang-SC-Regular, PingFang-SC;
                padding: 0 4px;
                display: flex;
                border: 1px solid #E44A59;
                border-left: none;
                border-radius: 0 2px 2px 0;
                box-sizing: border-box;
                justify-content: center;
                align-items: center;
                overflow: hidden;
              }
            }

            .vouchers-two {
              display: flex;
              justify-content: flex-start;
              align-items: center;
              height: 16px;
              line-height: 16px;
              box-sizing: border-box;

              .price-text {
                font-size: 10px;
                font-family: PingFang-SC-Regular, PingFang-SC;
                height: 16px;
                padding: 0 4px;
                color: #fff;
                background-color: #E44A59;
                border-radius: 2px 0 0 2px;
                display: flex;
                justify-content: center;
                align-items: center;
                overflow: hidden;
              }

              .price-gray {
                font-size: 10px;
                font-family: PingFang-SC-Regular, PingFang-SC;
                height: 16px;
                padding: 0 4px;
                color: #fff;
                background-color: #999999;
                border-radius: 2px 0 0 2px;
                display: flex;
                justify-content: center;
                align-items: center;
                overflow: hidden;
              }

              .price-number {
                font-size: 11px;
                height: 16px;
                font-family: PingFang-SC-Regular, PingFang-SC;
                padding: 0 2px;
                color: #E44A59;
                border: 1px solid #E44A59;
                box-sizing: border-box;
                border-radius: 0 2px 2px 0;
                display: flex;
                justify-content: center;
                align-items: center;
                overflow: hidden;
              }
            }

          }

          .goods-price {
            margin-top: 20px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .subsidies-price {
              height: 17px;
              font-size: 12px;
              font-family: PingFang-SC-Regular, PingFang-SC;
              font-weight: 400;
              color: #E44A59;
              line-height: 17px;
              line-height: 17px;

              .price {
                height: 28px;
                font-size: 20px;
                font-family: PingFang-SC-Regular, PingFang-SC;
                font-weight: bold;
                color: #e44a59;
                line-height: 28px;
              }
            }

            .grayfont {
              margin-top: 5px;
              height: 17px;
              font-size: 12px;
              font-family: PingFang-SC-Regular, PingFang-SC;
              font-weight: 400;
              color: #999999;
              line-height: 17px;

              .line-text {
                height: 17px;
                font-size: 12px;
                font-family: PingFang-SC-Regular, PingFang-SC;
                font-weight: 400;
                color: #999999;
                line-height: 17px;
                text-decoration: line-through;
              }
            }

            .button {
              position: absolute;
              bottom: 5px;
              right: 5px;
              display: inline-block;
              padding: 4px 7px 4px 13px;
              line-height: 20px;
              background: #E44A59;
              border-radius: 4px;

              & > span {
                display: flex;
                align-items: center;
                font-size: 14px;
                font-weight: 500;
                color: #ffffff;
                vertical-align: center;

                .van-icon {
                  font-size: 14px;
                }
              }
            }
          }
        }
      }

      .loading-wrap {
        font-size: 12px;
        line-height: 17px;
        text-align: center;
        padding: 15px 0;

        .loading {
          width: 35px;
          height: 35px;
          animation: loading 1s linear 0s infinite;
        }

        @keyframes loading {
          0% {
            transform: rotate(0deg);
          }
          100% {
            transform: rotate(360deg);
          }
        }

        & > p {
          color: #fff;
        }
      }

      .loading-wrap-white {
        margin-top: 6px;
      }
    }
  }
}
</style>
